/* General Styles */
html {
  scroll-behavior: smooth;
}

body, .navbar-custom, .custom-jumbotron, .portfolio-section {
  background-color: #4e4b42;
  color: #ebe6e2;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
  color: #ebe6e2;
}

.content-top-padding {
  padding-top: 5vh;
}

.jumbotron-hero, .custom-jumbotron {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ebe6e2;
  text-align: center;
}

.jumbotron-hero {
  height: 100vh;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.6);
}

.navbar-custom .nav-link, .navbar-custom .navbar-brand, .text-white {
  color: #ebe6e2;
}

.navbar-custom .nav-link:hover {
  color: #a5f3eb;
}

.nav-link:after{
  color: #4e4b42;
}

.portfolio {
  padding-top: 2rem;
}

.portfolio-section {
  padding: 3rem 0;
}

.portfolio-section h2, .modal-body h3, .project h3, .project-video h3 {
  font-size: 1.5rem;
  color: #ebe6e2;
}

.project, .project-video {
  text-align: center;
}

.project img, .embed-responsive, .gallery-grid .gallery-image, .modal-content, .card-overlay, .modal-image-fit {
  border-radius: 2px;
  transition: transform 0.3s ease-in-out;
}

.project img, .gallery-grid .gallery-image {
  max-width: 100%;
  height: auto;
  margin-top: 1rem;
}

.project img:hover, .gallery-grid .gallery-image:hover, .card-overlay:hover, .card-overlay img:hover {
  transform: scale(1.05);
}

.project p, .project-video p, .modal-body p, .card-overlay-text {
  color: #dcd9d4;
}

/* Card Overlay */
.card-overlay {
  position: relative;
  overflow: hidden;
}

.card-overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.8);
  font-size: 1.2em;
  opacity: 0;
}

.card-overlay:hover .card-overlay-text {
  opacity: 1;
}

.gallery-grid .gallery-image {
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.embed-responsive {
  overflow: hidden;
  margin-top: 1rem;
}

.modal-content {
  background-color: #262520;
  height: 75vh;
  padding: 0.5rem;
  overflow-y: auto;
}

.modal-dialog {
  max-width: 100%;
}

.modal-image-square-full-height {
  width: 75vh;
  height: 75vh;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.modal-image-fit {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
  margin: auto;
}

.video-container, .project-video .embed-responsive-item {
  height: 100%;
  border-radius: 2px;
  max-height: 720px;
  max-width: 1280px;
}

iframe {
  max-height: 720px;
  max-width: 1280px;
  padding-left: 5vw;
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 1.5rem;
  color: #ebe6e2;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: none;
}

.close-button:hover {
  color: #a5f3eb;
}


/* Responsive adjustments */
@media (max-width: 767px) {
  .modal-body h3, .modal-body p {
    text-align: center;
  }
}

.title{
  font-weight: bold;
}
